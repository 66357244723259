define("discourse/plugins/discourse-token-gate/discourse/components/modal/connect-web3-wallet", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-modal", "discourse-i18n", "discourse/plugins/discourse-token-gate/discourse/components/modal/modal-body", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dModal, _discourseI18n, _modalBody, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConnectWeb3WalletModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get modalType() {
      if (!this.currentUser.web3_public_key) {
        return "no_connected_wallet";
      }
      const hasSufficient = !!this.currentUser?.web3_tokens?.some(token => token.has_sufficient);
      if (!hasSufficient) {
        return "no_token";
      }
    }
    get modalTitle() {
      return (0, _discourseI18n.i18n)(`web3.modal.${this.modalType}.title`);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{this.modalTitle}}
          @closeModal={{@closeModal}}
          class="connect-web3-wallet-modal"
        >
          <ModalBodyComponent
            @allTokens={{this.allTokens}}
            @modalType={{this.modalType}}
            @currentUser={{this.currentUser}}
          />
        </DModal>
      
    */
    {
      "id": "CtzWc4/G",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"connect-web3-wallet-modal\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"modalTitle\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],null,[[\"@allTokens\",\"@modalType\",\"@currentUser\"],[[30,0,[\"allTokens\"]],[30,0,[\"modalType\"]],[30,0,[\"currentUser\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-token-gate/discourse/components/modal/connect-web3-wallet.js",
      "scope": () => [_dModal.default, _modalBody.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ConnectWeb3WalletModal;
});