define("discourse/plugins/discourse-token-gate/discourse/components/modal/modal-body", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModalBodyComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    #tokensGroupedByNameAndBalance() {
      const groupedData = {};
      const tokens = JSON.parse(this.siteSettings.web3_tokens || "[]");
      tokens.forEach(t => {
        const tokenName = t.token_name;
        const requiredBalance = t.required_token_balance;
        const key = `${tokenName}:${requiredBalance}`;
        let tokenBlockchain = t.blockchain;
        if (groupedData[key]) {
          tokenBlockchain = `${groupedData[key].tokenBlockchain}, ${tokenBlockchain}`;
        }
        groupedData[key] = {
          tokenName,
          requiredBalance,
          tokenBlockchain
        };
      });
      return groupedData;
    }
    get modalBodyText() {
      const groupedData = this.#tokensGroupedByNameAndBalance();
      const messages = Object.values(groupedData).map(data => {
        const key = `web3.modal.${this.args.modalType || "no_token"}.description`;
        return (0, _discourseI18n.i18n)(key, {
          href: `/u/${this.args.currentUser.username}/preferences/wallets`,
          requiredMinTokenBalance: data.requiredBalance,
          tokenName: data.tokenName,
          tokenBlockchain: data.tokenBlockchain
        });
      });
      return (0, _template.htmlSafe)(messages.join("<hr>"));
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{this.modalBodyText}}
      
    */
    {
      "id": "YoYD4pJd",
      "block": "[[[1,\"\\n    \"],[1,[30,0,[\"modalBodyText\"]]],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-token-gate/discourse/components/modal/modal-body.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ModalBodyComponent;
});